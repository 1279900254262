import React, { useContext, useEffect, useRef, useState } from "react";
import imagesvg from "../assets/images/splash.svg";
import { useNavigate } from "react-router-dom";
import { CompanyContext } from "../contexts/companyCotext";
import buildLink from "../urls";
import axios from "axios";
import { FaNetworkWired, FaSync } from "react-icons/fa";
import { IoMdWarning, IoMdWifi } from "react-icons/io";
import Cookies from "js-cookie";
import { LogisticContext } from "../contexts/logisticContext";

const StartPage = () => {
  const [state, dispatch] = useContext(CompanyContext);
  const [netErr, setNetErr] = useState(false);
  // const [openCompanies, setOpenCompanies] = useState(false);
  const history = useNavigate();
  const initialRender = useRef(true);
  // useEffect(() => {
  //   const userCash = localStorage.getItem("userID");
  //   if (userCash == null || userCash == "" || userCash == undefined) {
  //     history("/login", { replace: true });
  //   } else {
  //     login();
  //   }
  // }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      const userCash = localStorage.getItem("userID");
     const dateLoged =  Cookies.get("dateLoged");
     const currentDate = new Date().getDate();
    //  alert(!dateLoged == currentDate)
      if (userCash == null || userCash == "" || userCash == undefined ) {
        history("/login", { replace: true });
      } else {
        if(dateLoged == currentDate){
             login();
        }else{
          history("/login", { replace: true });
        }
       
      }
    }
  }, []);
  async function login() {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");

    const obj = {
      username,
      password,
      
    };
    if(window.location.host === 'pda.flo-lebanon.com' || window.location.host === "pda.englishhome.com.lb" ||  window.location.host.startsWith("localhost")){
      obj.web_pda = true
    }
    try {
      await axios.post(buildLink("login"), obj).then((response) => {
        if (response.data.success == true) {
          setNetErr(false);
          dispatch({ type: "setPermission", payload: response?.data?.permissions });
          dispatch({ type: "setUsername", payload: username });
          dispatch({ type: "setLoged", payload: true });
          dispatch({ type: "setUserId", payload: response.data.data.user_id });
          if(window.location.host == 'pda.flo-lebanon.com' || window.location.host === "pda.englishhome.com.lb"){
          dispatch({ type: "setCompany", payload: response.data.data.store_name });
          localStorage.setItem("store_id",   response.data.data.store_id)
          }
          // dispatch({ type: "setStoreId",   payload: response.data.data.store_id,});

          localStorage.setItem("username", username);
          localStorage.setItem("password", password);
          localStorage.setItem("userID",response.data.data.user_id );

          
          if( window.location.host == 'pda.ishtari.com' || window.location.host === "pda.ishtari.com.gh" ){
            history("/home/v2home", { replace: true });
          }else{
            history("/home", { replace: true });
          }
        } else {
          history("/login", { replace: true });
        }
      });
    } catch (e) {
      if(e.response){
        history("/login", { replace: true });
      }else{
        setNetErr(true);
      }
    
    }
  }

  //  async function selectCompany(company){
  //   dispatch({ type: "setCompany", payload: company })
  //    localStorage.clear();
  //    localStorage.setItem("company",company);
  //    history('/login')
  //  }

  return (
    <>
      <div className=" max-h-screen overflow-hidden h-screen  relative ">
        <div className="  -z-10 absolute top-0 right-0 left-0 bottom-0  h-screen w-screen ">
          <img className="  object-cover w-full h-full" src={imagesvg} />
        </div>
        {netErr && (
          <div  className="  h-screen relative  ">
            <div className=" h-fit absolute bottom-10 left-0 right-0 my-auto text-black py-5 mx-3 px-4 rounded-md  bg-[rgba(255,255,255,0.44)] ">
              <div className="flex flex-col  justify-center text-center gap-4 ">
                <div className=" w-fit mx-auto">
                  {" "}
                  <FaSync />
                </div>{" "}
                <h2 className=" flex gap-5 flex-row justify-center text-center">
                  {" "}
                  <IoMdWarning className=" text-dbase h-fit my-auto text-2xl" />{" "}
                  Netwrok Error ! Please Try again
                </h2>{" "}
                <button
                  onClick={() => login()}
                  className=" bg-white px-3 py-1 rounded-md"
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        )}
        {/* <div className={` ${openCompanies?"block":"hidden"} w-screen overflow-hidden h-screen top-0 left-0 bottom-0 right-0 absolute  bg-dblackOverlay  z-30`}></div>

<div  className={` transition-all ${openCompanies?" bottom-0":"-bottom-[200%]"} divide-y-2 absolute justify-start  text-left px-4  flex flex-col z-50  h-[300px]  divide-dplaceHolder bg-white overflow-auto   rounded-t-md w-full`}>
 
 <button onClick={()=> selectCompany('Ishtari')}  className='text-left w-full  outline-none px-3 py-3'> Ishtari </button>
 <button onClick={()=> selectCompany('Ishtari-Ghana')}  className='text-left w-full  outline-none px-3 py-3'> Ishtari-Ghana </button>
 <button onClick={()=> selectCompany('Ishtari-Usd')}  className=' text-left w-full  outline-none px-3 py-3'> Ishtari USD </button>
 <button onClick={()=> selectCompany('Energy-Plus')}  className='text-left w-full  outline-none px-3 py-3'> Energi-Plus </button>
 <button onClick={()=> selectCompany('Flo')}  className='text-left w-full  outline-none px-3 py-3'> FLO </button>
 <button onClick={()=> selectCompany('Flo-Beirut')}  className=' text-left w-full  outline-none px-3 py-3'> FLO Beirut </button>
 <button onClick={()=> selectCompany('Aalbeit')}  className='text-left w-full  outline-none px-3 py-3'> Aalbeit </button>
 <button onClick={()=> selectCompany('English-Home')}  className='text-left w-full  outline-none px-3 py-3'> English Home </button>
 <button onClick={()=> selectCompany('English-Home-1')} className='text-left w-full  outline-none px-3 py-3'> English Home Store 1 </button>
 <button onClick={()=> selectCompany('English-Home-2')}  className='text-left w-full  outline-none px-3 py-3'> English Home Store 2 </button>

</div> */}
      </div>
    </>
  );
};

export default StartPage;
