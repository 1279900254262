import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaBox,
  FaCheckCircle,
  FaCheckDouble,
  FaEllipsisV,
  FaHistory,
  FaListUl,
  FaPaperPlane,
  FaPlusCircle,
  FaQrcode,
  FaSearch,
  FaShoppingCart,
  FaSyncAlt,
  FaWifi,
  FaProductHunt,
  FaDropbox,
  FaWarehouse,
} from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import NavigateButton from "../../components/buttonsNavigate";
import { useNavigate } from "react-router-dom";

import { TabBarContext } from "../../contexts/TabBarContext";
import { HiArrowPath } from "react-icons/hi2";
import { CompanyContext } from "../../contexts/companyCotext";
import { MdOutlineManageHistory, MdOutlineWifiOff } from "react-icons/md";
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import { BiTransferAlt } from "react-icons/bi";
import { LiaTruckPickupSolid } from "react-icons/lia";
import hocStarredPage from "../../components/hocStarredPage";
import Cookies from "js-cookie";
import NavigateToFavorite from "../../components/navigateToFavorite";
import { GoVersions } from "react-icons/go";
import { FaEllipsis } from "react-icons/fa6";
import BurgerMenu from "../../components/burgerMenu";

const V2Home = () => {
  const [stateTab, dispatchTab] = useContext(TabBarContext);
  const [stateCom, dispatchCom] = useContext(CompanyContext);

  // const [wifiOnRemove,setWifiOnRemove] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const menuRefButton = useRef(null);
  //  const [wifiOn, setWifiOn] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [version, setVersion] = useState("");
  const favoritePage = Cookies.get("favoritePage");
  const navigate = useNavigate();
  const [state] = useContext(CompanyContext);
  const [hasNavigated, setHasNavigated] = useState(false);
  useEffect(() => {
    const navigateToFavorite = () => {
      if (favoritePage) {
        console.log("Favorite Page:", favoritePage);
        if (
          state.loged &&
          favoritePage &&
          window.location.pathname !== favoritePage
        ) {
          navigate(favoritePage);
          setHasNavigated(true);
          // setTimeout(() => {
          //   setHasNavigated(false)
          // }, 3000);
        }
        navigateToFavorite();
      }
    };
  }, []);

  useEffect(() => {
    console.log(window.location.host);
  }, []);

  const NavigateToFavorite = () => {
    const navigate = useNavigate();
    const [state] = useContext(CompanyContext);
    const [hasNavigated, setHasNavigated] = useState(false);

    useEffect(() => {
      if (!hasNavigated) {
        const favoritePage = Cookies.get("favoritePage");
        console.log("Favorite Page:", favoritePage);

        if (
          state.loged &&
          favoritePage &&
          window.location.pathname !== favoritePage
        ) {
          navigate(favoritePage);
          setHasNavigated(true);
          setTimeout(() => {
            setHasNavigated(false);
          }, 3000);
        }
      }
    }, [hasNavigated, navigate, state.loged, favoritePage]); // Added favoritePage to the dependency array

    // Empty div or null, as this component doesn't render anything
    return null;
  };

  // useEffect(() => {
  //   const navigateToFavorite = () => {

  //     console.log('Favorite Page:', favoritePage);

  //     if (state.loged && favoritePage && window.location.pathname !== favoritePage) {
  //       navigate(favoritePage);
  //     }
  //   };

  //   // Call navigateToFavorite when needed
  //   navigateToFavorite();
  //   NavigateToFavorite( );
  // }, []);

  // NavigateToFavorite( );

  useEffect(() => {
    const fetchAppVersion = async () => {
      try {
        const response = await fetch("/manifest.json");
        const manifest = await response.json();

        // Extract the app name from the manifest
        const { version } = manifest;
        dispatchCom({ type: "setVerison", payload: version });
        setVersion(version);
      } catch (error) {
        console.error("Error fetching or parsing manifest.json", error);
      }
    };

    fetchAppVersion();
  }, []);

  const handleClearSiteData = () => {
    serviceWorkerRegistration.unregister();
    if ("caches" in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
          window.location.reload();
        });
      });
    }
  };

  // useEffect(() => {
  //   const handleOnlineStatusChange = () => {
  //     setWifiOn(navigator.onLine);
  //     if(navigator.onLine){
  //       setWifiOnRemove(false)
  //       setTimeout(() => {
  //         setWifiOnRemove(true)
  //       }, 3000);
  //     }
  //   };

  //   // Add event listener to detect changes in online status
  //   window.addEventListener("online", handleOnlineStatusChange);
  //   window.addEventListener("offline", handleOnlineStatusChange);

  //   // Initial check for online status
  //   setWifiOn(navigator.onLine);

  //   // Cleanup by removing event listeners when component is unmounted
  //   return () => {
  //     window.removeEventListener("online", handleOnlineStatusChange);
  //     window.removeEventListener("offline", handleOnlineStatusChange);
  //   };
  // }, []);
  useEffect(() => {
    // const company = localStorage.getItem("company");
    if (
      stateCom.company === null ||
      stateCom.company === "" ||
      stateCom.company === undefined
    ) {
      navigate("/", { replace: true });
    }
    // else{
    //  dispatchCom({ type: "setCompany", payload: company })
    // }
  }, []);
  function logout() {
    localStorage.clear();
    navigate("/", { replace: true });
  }
  var host = window.location.host;

  return (
    <>
      <div className=" h-full bg-white">
        <div className="  sticky top-0 flex flex-col bg-white">
          <div
            onClick={() => setOpenMenu(false)}
            className={` ${
              openMenu ? "block" : "hidden"
            }  w-screen absolute z-10 top-0 left-0 bottom-0 right-0 bg-dblackOverlay h-screen`}
          ></div>
          {/* header */}
          <div className=" w-full bg-dbase h-16">
            <div className=" container flex relative flex-row justify-between w-full text-white  h-full my-auto">
              <div className="flex flex-col container">
                <h2 className=" text-xl my-auto">{stateCom.company} </h2>
                <h2
                  onClick={() => {
                    navigate("/home/v2home/graphpage");
                  }}
                  className=" cursor-pointer text-sm max-w-[100px] truncate my-auto "
                >
                  {stateCom.username}
                </h2>
              </div>
              <div className=" flex flex-row justify-center ">
                {/* <div className="  bg-dblackOverlay  h-fit  my-auto p-1 border rounded-full">
                  {" "}
                  {wifiOn ? (
                    <FaWifi className="text-dgreen2" />
                  ) : (
                    <MdOutlineWifiOff className="  text-dbase1" />
                  )}
                </div> */}
                {/* <button
                  className="h-fit  my-auto p-1 bg-dgreen  border rounded-full"
                  onClick={() => navigate("/home")}
                >
                  {" "}
                  <p className=" text-sm"> V2</p>
                </button> */}

                {/* <button
                  onClick={() => handleClearSiteData()}
                  className=" relative font-bold"
                >
                  <HiArrowPath />
                  <span class={`absolute ${stateCom.availableUpdate?"opacity-100":"opacity-0"}  top-4  -right-2 transition-all  flex h-2 w-2`}>
  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
  <span class="relative inline-flex rounded-full h-2 w-2  bg-white"></span>
</span>
                </button> */}
                {/* <button
                  ref={menuRefButton}
                  onClick={() => setOpenMenu(!openMenu)}
                >
                  {" "}
                  <FaEllipsisV />
                </button> */}

                <div className="flex justify-end p-4 mt-1">
                  <BurgerMenu />
                </div>
              </div>
              <div
                className={`${
                  openMenu ? "h-24" : "h-0"
                }  transition-all overflow-hidden  absolute  w-28 z-30 right-2 top-12 shadow-md bg-white rounded-md `}
              >
                <div
                  className={` divide-y-2 divide-dplaceHolder px-4 py-3  flex   flex-col gap-3 `}
                >
                  <button
                    onClick={() => console.log(stateCom.userID)}
                    className=" outline-none text-dblack  text-md   bg-transparent "
                  >
                    {version}
                  </button>
                  <button
                    onClick={() => logout()}
                    className=" outline-none text-dblack pt-2  text-md bg-transparent "
                  >
                    {" "}
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* tab bar */}
          <div className=" w-screen  bg-white my-4 container ">
            <div className=" flex w-full justify-center gap-2">
              <button
                onClick={() =>
                  dispatchTab({ type: "setActiveTab", payload: "home" })
                }
                className={`${
                  stateTab.activeTab === "home"
                    ? "bg-dbase text-white"
                    : "bg-white text-dblack"
                }  transition-all w-full py-2  rounded-full`}
              >
                {" "}
                Home
              </button>
              <button
                onClick={() =>
                  dispatchTab({ type: "setActiveTab", payload: "return" })
                }
                className={`${
                  stateTab.activeTab === "return"
                    ? "bg-dbase text-white"
                    : "bg-white text-dblack"
                }  transition-all w-full py-2 rounded-full`}
              >
                {" "}
                Return
              </button>
            </div>
          </div>
        </div>
        {/* buttons navigations */}
        <div className=" grid container gap-x-6 gap-y-4">
          {stateTab.activeTab === "home" && (
            <>
              {( host.startsWith("localhost") || host === "pda.ishtari.com") && (
                <>
                  <NavigateButton
                    icon={<FaWarehouse />}
                    name={"Warehouse Products"}
                    color={"bg-[rgb(252,186,3)] bg-opacity-90"}
                    onclick={() => navigate("warehousepage")}
                  />
                  <NavigateButton
                    icon={<FaDropbox />}
                    name={"ORDER"}
                    arname={"الطلبية"}
                    color={"bg-dbase1"}
                    onclick={() => navigate("orderpage")}
                  />
                  <NavigateButton
                    icon={<FaEllipsis />}
                    name={"Others"}
                    arname={"اخرى "}
                    color={"bg-[rgb(30,144,255)] bg-opacity-90"}
                    onclick={() => navigate("otherpage")}
                  />
                  <NavigateButton
                    icon={<FaPlusCircle />}
                    name={"JARDEH"}
                    arname={"جردة "}
                    color={"bg-[rgb(140,144,255)] bg-opacity-90"}
                    onclick={() => navigate("jardeh")}
                  />
                </>
              )}
              { ( host === "pda.ishtari.com.gh") && (
                <>
                  <NavigateButton
                    icon={<FaProductHunt />}
                    name={"PRODUCT"}
                    arname={"المنتج"}
                    color={"bg-dgreen bg-opacity-90"}
                    onclick={() => navigate("productpage")}
                  />
                  <NavigateButton
                    icon={<FaDropbox />}
                    name={"ORDER"}
                    arname={"الطلبية"}
                    color={"bg-dbase1"}
                    onclick={() => navigate("orderpage")}
                  />
                  <NavigateButton
                    icon={<FaEllipsis />}
                    name={"Others"}
                    arname={"اخرى "}
                    color={"bg-[rgb(30,144,255)] bg-opacity-90"}
                    onclick={() => navigate("otherpage")}
                  />
                   <NavigateButton
                    icon={<FaBox />}
                    name={"BOX LABELING"}
                    arname={"وضع العلامات على الصندوق"}
                    color={"bg-[rgb(140,144,255)] bg-opacity-90"}
                    onclick={() => navigate("box_labeling")}
                  />
                </>
              )}
              {/* {(!host.startsWith("localhost") ||
                host === "pda.ishtari.com.gh") && (
                <>
                  <NavigateButton
                    icon={<FaProductHunt />}
                    name={"PRODUCT"}
                    arname={"المنتج"}
                    color={"bg-dgreen bg-opacity-90"}
                    onclick={() => navigate("productpage")}
                  />
                  <NavigateButton
                    icon={<FaDropbox />}
                    name={"ORDER"}
                    arname={"الطلبية"}
                    color={"bg-dbase1"}
                    onclick={() => navigate("orderpage")}
                  />
                  <NavigateButton
                    icon={<FaEllipsis />}
                    name={"Others"}
                    arname={"اخرى "}
                    color={"bg-[rgb(30,144,255)] bg-opacity-90"}
                    onclick={() => navigate("otherpage")}
                  />
                  <NavigateButton
                    icon={<FaWarehouse />}
                    name={"Warehouse"}
                    color={"bg-[rgb(252,186,3)] bg-opacity-90"}
                    onclick={() => navigate("warehousepage")}
                  />
                </>
              )} */}
            </>
          )}
          {stateTab.activeTab === "return" && (
            <>
              {window.location.host !== "pda.ishtari.com.gh" &&
                window.location.host !== "pda.ishtari.com" && (
                  <NavigateButton
                    icon={<FaQrcode />}
                    name={"ARRIVED"}
                    arname={" وصل"}
                    color={"bg-[rgb(5,150,105)]"}
                    onclick={() => navigate("arrived")}
                  />
                )}
              <NavigateButton
                icon={<FaPaperPlane />}
                name={"Send To Control"}
                arname={"إرسال للتحكم "}
                color={"bg-[rgb(37,99,235)]"}
                onclick={() => navigate("sendtocontrol")}
              />

              {window.location.host !== "pda.ishtari.com.gh" && (
                <NavigateButton
                  icon={<FaCheckDouble />}
                  name={"Received in Control"}
                  arname={"تم استلامه في التحكم "}
                  color={"bg-[rgb(8,145,178)]"}
                  onclick={() => navigate("receivedincontrol")}
                />
              )}
              <NavigateButton
                icon={<IoSettingsSharp />}
                name={"Control"}
                arname={" التحكم"}
                color={"bg-[rgb(96,165,250)]"}
                onclick={() => navigate("control")}
              />
              <NavigateButton
                icon={<MdOutlineManageHistory />}
                name={"RETURN REASON FOR PRODUCT"}
                arname={"سبب استرداد المنتج"}
                color={"bg-[rgb(0,145,6)]"}
                onclick={() => navigate("rrfp")}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default V2Home;
